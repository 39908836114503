<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-overlay :show="isLoading">
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                        </b-row>
                                        <b-row>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Mill Name (En)" vid="mill_name" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="5"
                                                        label-for="mill_name"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.mill_name_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="mill_name"
                                                        v-model="millInformations.mill_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Mill Name (Bn)" vid="mill_name_bn" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="5"
                                                        label-for="mill_name_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.mill_name_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="mill_name_bn"
                                                        v-model="millInformations.mill_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Mill Address (En)" vid="address" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="5"
                                                        label-for="address"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.mill_address_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="address"
                                                        v-model="millInformations.address"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Mill Address (Bn)" vid="address_bn" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="5"
                                                        label-for="address_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.mill_address_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="address_bn"
                                                        v-model="millInformations.address_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="Contact person name (En)" vid="contact_person_name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="contact_person_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('bsri_config.mill_contact_person_name_en')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="contact_person_name"
                                                    v-model="millInformations.contact_person_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="Contact person name (Bn)" vid="contact_person_name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="contact_person_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('bsri_config.mill_contact_person_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="contact_person_name_bn"
                                                    v-model="millInformations.contact_person_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="Mobile No." vid="contact_person_mobile" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="contact_person_mobile"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('bsri_config.mill_contact_person_mobile_no')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    id="contact_person_mobile"
                                                    v-model="millInformations.contact_person_mobile"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="Mill contact person email" vid="contact_person_email">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="contact_person_email"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('bsri_config.mill_contact_person_email')}}
                                                </template>
                                                    <b-form-input
                                                    id="contact_person_email"
                                                    v-model="millInformations.contact_person_email"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        </b-row>
                                     <b-row>
                                    <b-col xl="6" lg="6" sm="6">
                                        <ValidationProvider name="Office Type" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="office_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.office_type_id"
                                                :options="officeTypeList"
                                                id="office_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="6">
                                        <ValidationProvider name="area_type_id" vid="area_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="area_type_id "
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.area_type_id"
                                                id="area_type_id"
                                                :options="getAreaTypeList"
                                                @change="getAreaTypeData(office.area_type_id)"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="6" v-show="ItemShow">
                                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="division_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.division_id"
                                                :options="divisionList"
                                                id="division_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="6" v-show="ItemShow">
                                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="6" v-show="PauroshobaItemShow || UnionItemShow">
                                        <ValidationProvider name="Upazila" vid="upazila_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="upazila_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.upazilla_id"
                                                :options="upazilaList"
                                                id="upazila_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="6" v-show="UnionItemShow">
                                        <ValidationProvider name="Union" vid="union_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="union_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.union_id"
                                                :options="unionList"
                                                id="union_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="6" v-show="CityCorpItemShow">
                                        <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="city_corporation_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.city_corporation_id"
                                                :options="cityCorporationList"
                                                id="city_corporation_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="6" v-show="PauroshobaItemShow">
                                        <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="pauroshoba_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.pauroshoba_id"
                                                :options="pauroshobaList"
                                                id="pauroshoba_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="6" v-show="ItemShow">
                                        <ValidationProvider name="Ward" vid="ward_id" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="ward_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.ward') }}
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="office.ward_id"
                                                :options="wardList"
                                                id="ward_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                        <b-row>
                                            <b-col>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                            </b-overlay>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { MillInformationStore, MillInformationUpdate, officeEntryStore, officeEntryUpdatetwo } from '../../api/routes'
import flatpickr from 'flatpickr'
export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        this.officeTypeList = this.getOfficeTypeList()
        if (this.id) {
            const tmp = this.getEditingData()
            this.millInformations = tmp
            this.thistmp = this.$store.state.commonObj.officeList.filter(item => item.value === this.millInformations.office_id)
            this.thistmp = JSON.parse(JSON.stringify(this.thistmp))
            this.getAreaTypeData(this.thistmp[0].area_type_id)
            this.office.org_id = this.thistmp[0].org_id
            this.office.office_type_id = this.thistmp[0].office_type_id
            this.office.area_type_id = this.thistmp[0].area_type_id
            this.office.city_corporation_id = this.thistmp[0].city_corporation_id
            this.office.pauroshoba_id = this.thistmp[0].pauroshoba_id
            this.office.ward_id = this.thistmp[0].ward_id
            this.office.country_id = this.thistmp[0].country_id
            this.office.division_id = this.thistmp[0].division_id
            this.office.district_id = this.thistmp[0].district_id
            this.office.upazilla_id = this.thistmp[0].upazilla_id
            this.office.union_id = this.thistmp[0].union_id
            this.office.office_name = this.thistmp[0].text_en
            this.office.office_name_bn = this.thistmp[0].text_bn
            this.office.office_code = this.thistmp[0].office_code
            this.office.parent_office_type_id = 1
            this.office.parent_office_id = 1
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            isLoading: false,
            thistmp: [],
            ItemShow: false,
            CityCorpItemShow: false,
            PauroshobaItemShow: false,
            UnionItemShow: false,
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            pauroshobaList: [],
            millInformations: {
                mill_name: '',
                mill_name_bn: '',
                address: '',
                address_bn: '',
                contact_person_name: '',
                contact_person_name_bn: '',
                contact_person_mobile: '',
                contact_person_email: '',
                office_id: ''
            },
              office: {
                org_id: 8,
                office_type_id: '0',
                area_type_id: '0',
                city_corporation_id: '0',
                pauroshoba_id: '0',
                ward_id: '0',
                country_id: 1,
                division_id: '0',
                district_id: '0',
                upazilla_id: '0',
                union_id: '0',
                office_name: '',
                office_name_bn: '',
                office_code: '1',
                parent_office_type_id: '0',
                parent_office_id: '0',
                created_by: 1,
                updated_by: 1,
                is_regional_office: 0
            },
            officeTypeList: [],
            districtList: [],
            wardList: [],
            grant_types: [
                {
                    value: 1,
                    text: 'National',
                    text_bn: 'জাতীয়'
                },
                {
                    value: 2,
                    text: 'International',
                    text_bn: 'আন্তর্জাতিক'
                }
            ]
        }
    },
    watch: {
        'office.org_id': function (newVal, oldVal) {
            this.officeTypeList = this.getOfficeTypeList(newVal)
        },
        'office.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'office.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'office.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'office.city_corporation_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByCityCorportaion(newVal)
        },
        'office.pauroshoba_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByPauroshoba(newVal)
        },
        'office.union_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByUnion(newVal)
        },
        'millInformations.mill_name': function () {
          this.office.office_name = this.millInformations.mill_name
        },
        'millInformations.mill_name_bn': function () {
          this.office.office_name_bn = this.millInformations.mill_name_bn
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        millTypeList: function () {
            const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
            return millTypeList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        grantTypeList: function () {
            return this.grant_types.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        async saveData () {
            this.isLoading = true
            let result = null
            this.millInformations.office_type_id = this.office.office_type_id
            if (this.id) {
                if (this.id) {
                result = RestApi.putData(incentiveGrantServiceBaseUrl, `${MillInformationUpdate}/${this.id}`, this.millInformations).then(response => {
                if (response.success) {
                    this.office.office_id = this.millInformations.office_id
                    this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                    RestApi.postData(commonServiceBaseUrl, officeEntryUpdatetwo, this.office).then(response => {
                    })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.isLoading = false
                this.$bvModal.hide('modal-4')
                } else {
                this.$refs.form.setErrors(result.errors)
                 this.isLoading = false
                }
                })
                 } else {
                result = RestApi.postData(incentiveGrantServiceBaseUrl, MillInformationStore, this.millInformations).then(response => {
                if (response.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.isLoading = false
                this.$bvModal.hide('modal-4')
                } else {
                this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'The mill name has already been taken update',
                color: '#D6E09B'
                })
                }
                })
                }
            } else {
                result = await RestApi.postData(commonServiceBaseUrl, officeEntryStore, this.office).then(response => {
                    if (response.success) {
                        this.millInformations.office_id = response.id
                        if (this.id) {
                            result = RestApi.putData(incentiveGrantServiceBaseUrl, `${MillInformationUpdate}/${this.id}`, this.millInformations).then(response => {
                            if (response.success) {
                                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                                this.$toast.success({
                                title: this.$t('globalTrans.success'),
                                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                                color: '#D6E09B'
                                })
                                this.isLoading = false
                                this.$bvModal.hide('modal-4')
                            } else {
                                this.$refs.form.setErrors(result.errors)
                                this.isLoading = false
                            }
                            })
                        } else {
                            result = RestApi.postData(incentiveGrantServiceBaseUrl, MillInformationStore, this.millInformations).then(response => {
                                if (response.success) {
                                    this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                                    this.$toast.success({
                                    title: this.$t('globalTrans.success'),
                                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                                    color: '#D6E09B'
                                    })
                                    this.isLoading = false
                                    this.$bvModal.hide('modal-4')
                                } else {
                                    this.$toast.error({
                                    title: this.$t('globalTrans.error'),
                                    message: 'The mill name has already been taken update',
                                    color: '#D6E09B'
                                    })
                                }
                            })
                        }
                    } else {
                        this.isLoading = false
                        this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: 'The mill name has already been taken',
                        color: '#D6E09B'
                    })
                }
            })
            }
        },
        getOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === 8)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === 8).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
            getWardListByCityCorportaion (cityCorpId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByUnion (unionId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
    }
}
</script>
